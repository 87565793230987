import _ from 'lodash';

import {
  Agreement,
  CreateUserTermsAgreement,
  EnrollmentUser,
  EnrollmentUserType,
  SignInUser,
  UpdatePaymentMethod,
} from 'ev-types';

import { Base, Tags } from 'ev-api/api';
import { clearCache } from 'ev-api/cache-utils';
import {
  sanitizeAndTransformResponse,
  sanitizeAndTransformResponseData,
} from 'ev-api/common/transformers';
import {
  CreateUserResponse,
  currentUserApi,
  GetEnrollmentUserFailureResponse,
  GetEnrollmentUserSuccessResponse,
  ResetPasswordResponse,
  UpdatePasswordSuccessResponse,
} from 'ev-api/core';
import { setAuthToken } from 'ev-store/actions';
import { isObject } from 'ev-utils/types';

import {
  CreateUserFromInvitationParams,
  CreateUserParams,
  CreateUserTermsAgreementParams,
  DeleteCreditCardParams,
  GetEnrollmentUserParams,
  GetUserAgreementsParams,
  ResetPasswordParams,
  SignInParams,
  SignInWithTokenParams,
  UpdateAddressParams,
  UpdateAddressResponse,
  UpdatePasswordParams,
  UpdateStripePaymentMethodParams,
  UpdateUserTimezoneParams,
  UpdateUSIOPaymentMethodParams,
} from './params';
import { enrollmentUserTransform } from './transformers';

const responseInlcudesDataProperty = (
  value: unknown,
): value is GetEnrollmentUserSuccessResponse => {
  return isObject(value) && isObject(value.data);
};

export const isEnrollmentUser = (value: unknown): value is EnrollmentUser => {
  return isObject(value) && value.type === EnrollmentUserType;
};

export type GetEnrollmentUserResult =
  | EnrollmentUser
  | GetEnrollmentUserFailureResponse;

const usersApi = currentUserApi.injectEndpoints({
  endpoints: builder => ({
    signIn: builder.mutation<SignInUser, SignInParams>({
      query: ({
        email,
        password,
        consent,
        timezone,
        practiceId,
        restrictedVisitSignin,
      }) => ({
        url: `${Base.V3}/users/sign_in.json`,
        method: 'POST',
        headers: {
          'practice-id': practiceId,
        },
        body: {
          restricted_visit_signin: restrictedVisitSignin,
          user: { email, password, timezone, tags: null },
          consent,
        },
      }),
      async onQueryStarted(
        { skipResetApiState },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;
          if (skipResetApiState !== true) {
            clearCache(dispatch);
          }

          dispatch(setAuthToken(data.attributes.secure_authentication_token));
        } catch {
          /* empty */
        }
      },
      transformResponse: sanitizeAndTransformResponse,
    }),
    signInWithToken: builder.mutation<SignInUser, SignInWithTokenParams>({
      query: ({ token, restrictedVisitSignin }) => ({
        url: `${Base.V3}/users/sign_in.json`,
        method: 'POST',
        body: {
          tags: null,
          secure_authentication_token: token,
          restricted_visit_signin: restrictedVisitSignin,
        },
      }),
      async onQueryStarted(
        { skipResetApiState },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data } = await queryFulfilled;
          if (skipResetApiState !== true) {
            clearCache(dispatch);
          }
          dispatch(setAuthToken(data.attributes.secure_authentication_token));
        } catch {
          /* empty */
        }
      },
      transformResponse: sanitizeAndTransformResponse,
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordParams>(
      {
        query: ({ email, practiceHandle }) => ({
          url: `${Base.None}/users/password.json`,
          method: 'POST',
          body: {
            user: {
              email,
              practiceHandle,
              passwordResetRequestedFrom: 'rad',
            },
          },
        }),
      },
    ),
    updatePassword: builder.mutation<
      UpdatePasswordSuccessResponse,
      UpdatePasswordParams
    >({
      query: ({ password, passwordConfirmation, token }) => ({
        url: `${Base.None}/users/password.json`,
        method: 'PATCH',
        body: {
          user: {
            password,
            password_confirmation: passwordConfirmation,
            reset_password_token: token,
          },
        },
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: `${Base.None}/users/sign_out.json`,
        method: 'GET',
      }),
    }),
    getEnrollmentUser: builder.mutation<
      GetEnrollmentUserResult,
      GetEnrollmentUserParams
    >({
      query: ({ email }) => {
        return {
          url: `${Base.V2}/users/email_enroll`,
          method: 'POST',
          body: {
            email,
          },
          validateStatus: (response, result) => {
            return (
              response.status === 200 ||
              // Workaround for the fact that the API should return 400 in this case
              (response.status === 400 &&
                _.get(result, 'reason') === 'user-not-found') ||
              (response.status === 400 &&
                _.get(result, 'reason') === 'user-not-new')
            );
          },
        };
      },
      transformResponse: (response: GetEnrollmentUserResult) => {
        if (responseInlcudesDataProperty(response)) {
          return enrollmentUserTransform(response);
        }

        return response;
      },
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserParams>({
      query: ({
        email,
        password,
        password_confirmation,
        invited_by_id,
        invited_by_type,
        evisit_tos,
        practice_tos,
        role,
        timezone,
      }) => ({
        url: `${Base.None}/users/invitation.json`,
        method: 'PUT',
        body: {
          lite: true,
          members_lite: true,
          user: {
            email,
            password,
            password_confirmation,
            invited_by_id,
            invited_by_type,
            evisit_tos: evisit_tos ? 'true' : undefined,
            practice_tos: practice_tos ? 'true' : undefined,
            role,
            timezone,
          },
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.User],
    }),
    createUserFromInvitation: builder.mutation<
      CreateUserResponse,
      CreateUserFromInvitationParams
    >({
      query: ({
        id,
        invitation_token,
        password,
        password_confirmation,
        evisit_tos,
        practice_tos,
      }) => ({
        url: `${Base.None}/users/invitation.json`,
        method: 'PUT',
        body: {
          lite: true,
          members_lite: true,
          user: {
            id,
            invitation_token,
            password,
            password_confirmation,
            evisit_tos: evisit_tos ? 'true' : undefined,
            practice_tos: practice_tos ? 'true' : undefined,
          },
        },
      }),
      transformResponse: sanitizeAndTransformResponseData,
      invalidatesTags: [Tags.User],
    }),
    updateAddress: builder.mutation<UpdateAddressResponse, UpdateAddressParams>(
      {
        query: ({ addressId, userId, address }) => ({
          url: addressId
            ? `${Base.V2}/users/${userId}/addresses/${addressId}`
            : `${Base.V2}/users/${userId}/addresses`,
          method: addressId ? 'PUT' : 'POST',
          body: address,
        }),
        async onQueryStarted(params, { dispatch, queryFulfilled }) {
          const { data: result } = await queryFulfilled;
          //update just the address field of currentUser, so we won't alter the avatar if it's being updated still
          dispatch(
            currentUserApi.util.updateQueryData(
              'getCurrentUser',
              undefined,
              currentUser => {
                Object.assign(currentUser, result);
              },
            ),
          );
        },
      },
    ),
    getUserAgreements: builder.query<Agreement[], GetUserAgreementsParams>({
      query: ({ id, offset = 0, limit = 20, all_distinct = false }) => ({
        url: `${Base.V3}/users/${id}/user_terms_agreements`,
        params: { offset, limit, all_distinct },
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
    createUserTermsAgreements: builder.mutation<
      CreateUserTermsAgreement,
      CreateUserTermsAgreementParams
    >({
      query: ({ userId, agreements }) => ({
        url: `${Base.V3}/users/${userId}/user_terms_agreements`,
        method: 'POST',
        body: { agreements },
      }),
      invalidatesTags: (_result, _error, args) =>
        args.skipTagsInvalidation ? [] : [Tags.User],
      transformResponse: sanitizeAndTransformResponseData,
    }),
    updateStripePaymentMethod: builder.mutation<
      UpdatePaymentMethod,
      UpdateStripePaymentMethodParams
    >({
      query: ({ token, userId }) => ({
        url: `${Base.V2}/users/${userId}/cards.json`,
        method: 'POST',
        body: { ...token, userID: userId, processor: 'stripe' },
      }),
      invalidatesTags: [Tags.User],
    }),
    updateUSIOPaymentMethod: builder.mutation<
      UpdatePaymentMethod,
      UpdateUSIOPaymentMethodParams
    >({
      query: ({ card, userId }) => ({
        url: `${Base.V2}/users/${userId}/cards.json`,
        method: 'POST',
        body: {
          ...card,
          expiryYear: `20${card.expiryYear}`,
          userID: userId,
          processor: 'usio',
        },
      }),
      invalidatesTags: [Tags.User],
    }),
    deleteCreditCard: builder.mutation<void, DeleteCreditCardParams>({
      query: ({ userId, cardId }) => ({
        url: `${Base.V2}/users/${userId}/cards/${cardId}.json`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.User],
    }),
    updateUserTimezone: builder.mutation<void, UpdateUserTimezoneParams>({
      query: ({ userId, timezone, timezoneOverride }) => ({
        url: `${Base.V2}/users/${userId}`,
        method: 'PUT',
        body: { timezone, timezone_override: timezoneOverride },
      }),
      invalidatesTags: [Tags.User],
    }),
  }),
});

export const {
  useCreateOrUpdateProfileMutation,
  useSignOutMutation,
  useToggleAvailabilityMutation,
  useCreateDependentMutation,
  useUnpublishProfileMutation,
  useUpdateUserMutation,
  useUpdateUserEmailMutation,
  useUpdateUserLastPracticeMutation,
  useUpdateUserPasswordMutation,
  useUpdateDependentMutation,
  useUpdateAddressMutation,
  useUpdateAvatarMutation,
  useDeleteAvatarMutation,
  useKeepAliveMutation,
  useUpdateStripePaymentMethodMutation,
  useUpdateUSIOPaymentMethodMutation,
  useDeleteCreditCardMutation,

  useLazyGetUserAgreementsQuery,
  useSignInMutation,
  useSignInWithTokenMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useCreateUserTermsAgreementsMutation,
  useCreateUserMutation,
  useCreateUserFromInvitationMutation,
  useGetEnrollmentUserMutation,
  useUpdateUserTimezoneMutation,
} = usersApi;
