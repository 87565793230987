import _ from 'lodash';

import { nt } from 'ev-i18n';
import {
  CalendarBlock,
  DynamicFormField,
  DynamicFormFieldAttributes,
  Owner,
  Practice,
  PracticeDevice,
  ResponseId,
  Verbiages,
} from 'ev-types';

import {
  BaseIncludedElement,
  IncludedPracticeElementResponse,
  IncludedQuestionsElementResponse,
} from 'ev-api/common/IncludedElementResponse';
import { Sanitized, sanitizeId, sanitizeIds } from 'ev-api/utils';

import { transformChartQuestions } from './charting/transformers';
import {
  Azadb2cProviderResponseAttributes,
  CalendarBlockResponseAttributes,
  CalendarBlocksResponse,
  CustomVerbiagesResponse,
  PracticeDataResponse,
  PracticeDevicesResponse,
  PracticeRelationshipsResponse,
  PracticeResponse,
  PracticesResponse,
  SupportedInsuranceFormsResponse,
  VisitLinkForDeviceResponse,
} from './responses';

type RelationshipData = {
  type: string;
  id: ResponseId;
};

const findCorrespondingIncludedElement = (
  id: ResponseId,
  type: string,
  includedByType: BaseIncludedElement[],
) => {
  if (!id) {
    return;
  }
  return _.find(includedByType, { id, type });
};

const findCorrespondingDynamicFormIds = (
  id: ResponseId,
  type: string,
  keyToMatch: IncludedQuestionsElementResponse[],
  dynamicFormField: DynamicFormField[],
) => {
  if (!id) {
    return;
  }
  const currentKey = _.find(keyToMatch, { id, type });
  if (currentKey?.relationships?.questions) {
    const keyIds = currentKey.relationships.questions.data;
    return keyIds.map(keyId => {
      return _.find(
        dynamicFormField,
        (field: DynamicFormField) => field.id === keyId.id,
      );
    });
  }
  return keyToMatch;
};

const transformPracticeData = (
  practiceData: Sanitized<PracticeDataResponse>,
  included?: IncludedPracticeElementResponse[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allIncludedByType: any = _.groupBy(included, 'type');
  // dynamic_form_field may be composed of different types
  // (like questions or ratings), so we'll need to separate them
  // and add them to their corresponding key (where, currently only the ids or the questions are stored)
  const visitSurvey = practiceData.relationships?.visit_survey;
  const miscQuestion = practiceData.relationships?.misc_question;
  const address = practiceData.relationships?.address;
  const azadb2cProviders = practiceData.relationships?.azadb2c_providers;
  const customer = practiceData.relationships?.customer;
  const customActions = practiceData.relationships?.custom_actions;

  const matchingAzadb2cProviders: Azadb2cProviderResponseAttributes[] = [];
  if (azadb2cProviders?.data.length) {
    _.each(azadb2cProviders.data, azadb2cProvider => {
      const matchingAzadb2cProvider = findCorrespondingIncludedElement(
        azadb2cProvider.id,
        azadb2cProvider.type,
        allIncludedByType.azadb2c_provider,
      );
      if (matchingAzadb2cProvider) {
        matchingAzadb2cProviders.push(matchingAzadb2cProvider.attributes);
      }
    });
  }
  allIncludedByType.azadb2c_providers = matchingAzadb2cProviders;

  let matchingVisitSurveys;
  if (visitSurvey) {
    matchingVisitSurveys = findCorrespondingDynamicFormIds(
      visitSurvey.data.id,
      visitSurvey.data.type,
      allIncludedByType.visit_survey,
      allIncludedByType.dynamic_form_field,
    );
  }
  allIncludedByType.visit_survey_questions =
    matchingVisitSurveys || visitSurvey;
  allIncludedByType.visit_survey = allIncludedByType.visit_survey
    ? allIncludedByType.visit_survey[0].attributes
    : null;

  let matchingMiscQuestion;
  if (miscQuestion) {
    matchingMiscQuestion = findCorrespondingDynamicFormIds(
      miscQuestion.data.id,
      miscQuestion.data.type,
      allIncludedByType.misc_question,
      allIncludedByType.dynamic_form_field,
    );
  }
  allIncludedByType.misc_question = matchingMiscQuestion || miscQuestion;

  let matchingAddress;
  if (address?.data?.id) {
    matchingAddress = findCorrespondingIncludedElement(
      address.data.id,
      address.data.type,
      allIncludedByType.address,
    );
  }
  allIncludedByType.address = matchingAddress || null;

  let matchingCustomer;
  if (customer?.data?.id) {
    matchingCustomer = findCorrespondingIncludedElement(
      customer.data.id,
      customer.data.type,
      allIncludedByType.customer,
    );
  }
  allIncludedByType.customer = matchingCustomer || null;

  allIncludedByType.chart_questions = transformChartQuestions(
    allIncludedByType,
    'chart_question',
    'chart_question_template',
    'chart_question_template_question',
  );

  allIncludedByType.attestation_questions = transformChartQuestions(
    allIncludedByType,
    'attestation_question',
    'attestation_question_template',
    'attestation_robust',
  );

  allIncludedByType.custom_actions = customActions?.data
    .map(
      action =>
        findCorrespondingIncludedElement(
          action.id,
          'custom_action',
          allIncludedByType.custom_action,
        )?.attributes,
    )
    .filter(Boolean);

  return {
    ...practiceData,
    ...allIncludedByType,
  };
};

export function practicesTransform(response: PracticesResponse): Practice[] {
  const sanitizedResponse = sanitizeIds(response);

  return sanitizedResponse.data.map(data => {
    const knownRelationships = new Set<string>();

    const addRelationshipToSet = (rel: RelationshipData) => {
      knownRelationships.add(`${rel.type}:${rel.id}`);
    };

    const processRelationships = (
      relationships?: PracticeRelationshipsResponse,
    ) => {
      if (!relationships) {
        return;
      }
      Object.values(relationships).forEach(relationship => {
        if (Array.isArray(relationship.data)) {
          relationship.data.forEach(item =>
            addRelationshipToSet(item as RelationshipData),
          );
        } else if (relationship.data) {
          addRelationshipToSet(relationship.data);
        }
      });
    };

    processRelationships(data.relationships);

    const preFilteredIncluded =
      sanitizedResponse.included?.filter(included =>
        knownRelationships.has(`${included.type}:${included.id}`),
      ) || [];

    preFilteredIncluded.forEach(included =>
      processRelationships(included.relationships),
    );

    const filteredIncluded =
      sanitizedResponse.included?.filter(included =>
        knownRelationships.has(`${included.type}:${included.id}`),
      ) || [];

    return transformPracticeData(data, filteredIncluded);
  });
}

export function practicesToPracticeTransform(
  response: PracticesResponse,
): Practice {
  return practicesTransform(response)[0];
}

export const practiceTransform = (response: PracticeResponse): Practice => {
  const sanitizedResponse = sanitizeIds(response);
  return transformPracticeData(
    sanitizedResponse.data,
    sanitizedResponse.included,
  );
};

export const calendarBlockResponseTransform = (
  response: CalendarBlocksResponse,
): CalendarBlock[] => {
  return _.map(response.data, data => {
    return calendarBlockTransform(data.attributes);
  });
};

export const calendarBlockTransform = (
  response: CalendarBlockResponseAttributes,
): CalendarBlock => {
  return {
    ...response,
    begin_datetime: response.begin_datetime || nt('Invalid date'),
    end_datetime: response.end_datetime || nt('Invalid date'),
    id: sanitizeId(response.id),
    owner_id: sanitizeId(response.owner_id),
    owner_type: response.owner_type.toLowerCase() as Owner,
    schedulability_id: sanitizeId(response.schedulability_id),
    user_id: sanitizeId(response.user_id),
  };
};

export const supportedInsuranceFormsTransform = (
  response: SupportedInsuranceFormsResponse,
): Record<string, DynamicFormFieldAttributes> => {
  const { included } = response;

  const result: Record<string, DynamicFormFieldAttributes> = {};

  _.each(included, value => {
    const { attributes } = value;
    result[attributes.name] = {
      ...attributes,
      id: sanitizeId(attributes.id),
    };
  });

  return result;
};

export const practiceCustomVerbiagesTransform = (
  response: CustomVerbiagesResponse,
): Verbiages => {
  const sanitized = sanitizeIds(response);
  return {
    customVerbiages: sanitized.data,
    defaultVerbiages: sanitized.meta.definitions,
  };
};

export const practiceDevicesTransform = (
  response: PracticeDevicesResponse,
): PracticeDevice[] => {
  return response.data.map(device => device.attributes);
};

export const visitLinkForDeviceTransform = (
  response: VisitLinkForDeviceResponse,
): string | undefined => {
  return response.link;
};
