import _ from 'lodash';
import { useState } from 'react';

import { Practice } from 'ev-types';

import { useNullableCurrentUser } from 'ev-hooks/commonData';
import { redirect } from 'ev-utils/browser';

export const useSSOInfo = () => {
  const currentUser = useNullableCurrentUser();
  const postSSOLogoutURL =
    currentUser?.attributes.post_sign_out_target_url ?? null;

  return {
    loggedInWithSSO: !!postSSOLogoutURL,
    postSSOLogoutURL,
  };
};

export const useSSOLogin = (practice?: Practice) => {
  const [redirected, setRedirected] = useState(false);

  const b2cSSOProvider = _.find(
    practice?.authentication_method,
    auth => auth.attributes.sso_provider_type === 'Azadb2cProvider',
  );
  if (!!b2cSSOProvider && !redirected) {
    redirect(b2cSSOProvider.attributes.login_url);
    setRedirected(true);
  }
  return { isSSOEnabled: !!b2cSSOProvider };
};
