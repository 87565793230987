import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import {
  useNavigate, // eslint-disable-line no-restricted-imports
  useSearchParams,
} from 'react-router-dom';

import {
  setHasUnsavedChanges,
  setShowUnsavedChangesDialog,
} from 'ev-store/actions';
import { useAppDispatch, useAppSelector } from 'ev-store/redux';

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const isAdminPath = window.location.pathname.includes('/app_admin/');
  const [searchParams] = useSearchParams(
    isAdminPath ? window.location.search : undefined,
  );

  const dispatch = useAppDispatch();
  const { hasUnsavedChanges, showUnsavedChangesDialog } = useAppSelector(
    state => state.unsavedChanges,
  );

  const handleBackNavigation = useCallback(() => {
    localStorage.setItem('unsavedChanges', 'false');
    dispatch(setHasUnsavedChanges(false));
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const unsavedChanges = localStorage.getItem('unsavedChanges');
      if (unsavedChanges === 'true' || hasUnsavedChanges) {
        handleBackNavigation();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBackNavigation, hasUnsavedChanges, showUnsavedChangesDialog]);

  useEffect(() => {
    window.addEventListener('popstate', handleBackNavigation);

    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, [handleBackNavigation]);

  return useCallback(
    (
      target: string | number,
      params?: URLSearchParams | null,
      replace?: boolean,
    ) => {
      const unsavedChanges = localStorage.getItem('unsavedChanges');

      if (unsavedChanges === 'true') {
        console.warn(t('Navigation is blocked due to unsaved changes.'));
        return;
      }

      if (hasUnsavedChanges) {
        dispatch(setShowUnsavedChangesDialog(true));
        return;
      }

      if (isAdminPath) {
        const paramsKeys = [...searchParams.keys()];
        paramsKeys.forEach(k => {
          if (k !== 'customerId') {
            searchParams.delete(k);
          }
        });
      }

      if (typeof target === 'string') {
        navigate(
          {
            pathname: target,
            search: '?' + (params ?? searchParams).toString(),
          },
          { replace },
        );
      } else {
        navigate(target);
      }
    },
    [isAdminPath, navigate, searchParams, hasUnsavedChanges, dispatch],
  );
};

export const useParamsString = () => {
  const [searchParams] = useSearchParams();
  const value = searchParams.toString();
  if (value.length > 0) {
    return `?${value}`;
  }
  return '';
};

export const useUpdatePracticeSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return useCallback(
    (value: string) => {
      searchParams.delete('segment');
      searchParams.set('practice', value);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );
};
