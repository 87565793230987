import _ from 'lodash';

import {
  PatientStatus,
  PersonalFilterView,
  PersonalFilterViewType,
} from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { PersonalFilterViewResponseData } from 'ev-api/core';
import { SortingOptions } from 'ev-api/types';
import { isObject } from 'ev-utils/types';

import {
  CreatePersonalFilterViewParams,
  DeletePersonalFilterViewParams,
  GetPersonalFilterViewsParams,
  UpdatePersonalFilterViewParams,
} from './params';
import {
  CreatePersonalFilterViewResponse,
  UpdatePersonalFilterViewResponse,
} from './responses';
import {
  personalFilterViewsTransform,
  personalFilterViewTransform,
} from './transformers';

type CreatePersonalFilterViewResult =
  | {
      success: boolean;
      error_codes: string[];
    }
  | PersonalFilterView;

type UpdatePersonalFilterViewResult = CreatePersonalFilterViewResult;

const buildPatientStatusParam = ({
  arrived,
  ready,
}: {
  arrived: boolean;
  ready: boolean;
}) => {
  const param = [];
  if (arrived) {
    param.push(PatientStatus.Arrived);
  }
  if (ready) {
    param.push(PatientStatus.Ready);
  }

  return param;
};

const isPersonalFilterViewResponse = (
  value: unknown,
): value is { data: PersonalFilterViewResponseData } => {
  return (
    isObject(value) &&
    isObject(value.data) &&
    value.data.type === 'personal_filter_view'
  );
};

export const isPersonalFilterView = (
  value: unknown,
): value is PersonalFilterView => {
  return isObject(value) && value.type === PersonalFilterViewType;
};

const personalFilterViewsApi = api.injectEndpoints({
  endpoints: builder => ({
    getPersonalFilterViews: builder.query<
      PersonalFilterView[],
      GetPersonalFilterViewsParams
    >({
      query: ({ practice_id }) => ({
        url: `${Base.V3}/personal_filter_views`,
        method: 'GET',
        params: { practice_id },
      }),
      transformResponse: personalFilterViewsTransform,
      providesTags: [Tags.PersonalFilterViews],
    }),
    createPersonalFilterView: builder.mutation<
      CreatePersonalFilterViewResult,
      CreatePersonalFilterViewParams
    >({
      query: ({
        isDefault,
        name,
        practiceId,
        userId,
        readyFilter,
        arrivedFilter,
        providerIds,
        gridDensity,
        sortBy = SortingOptions.EarliestToLatest,
        hiddenFields,
        columnsProps,
        columnFiltering,
        columnSorting,
        columnsVisibility,
      }) => {
        const stringifiedColumnsProps = JSON.stringify(columnsProps);
        const stringifiedColumnFilters = JSON.stringify(columnFiltering);
        const stringifiedColumnSorting = JSON.stringify(columnSorting);
        const stringifiedColumnsVisibility = JSON.stringify(columnsVisibility);
        return {
          url: `${Base.V3}/personal_filter_views`,
          method: 'POST',
          body: {
            area: 'waiting_room',
            data: {
              version: 1,
              provider_ids: providerIds,
              patient_statuses: buildPatientStatusParam({
                arrived: arrivedFilter,
                ready: readyFilter,
              }),
              grid_density: gridDensity,
              hidden_fields: hiddenFields,
              sort_by: sortBy,
              columns_props: stringifiedColumnsProps,
              column_filtering: stringifiedColumnFilters,
              column_sorting: stringifiedColumnSorting,
              columns_visibility: stringifiedColumnsVisibility,
            },
            default: isDefault,
            name: _.trim(name),
            practice_id: practiceId,
            user_id: userId,
          },
          validateStatus: (response, result) => {
            return (
              response.status === 200 ||
              // Workaround for the fact that the API should return 409 in this case
              (response.status === 400 &&
                _.get(result, 'error_codes[0]') === 'duplicate-name')
            );
          },
        };
      },
      invalidatesTags: [Tags.PersonalFilterViews],
      transformResponse: (response: CreatePersonalFilterViewResponse) => {
        if (isPersonalFilterViewResponse(response)) {
          return personalFilterViewTransform(response.data);
        }

        return response;
      },
    }),
    updatePersonalFilterView: builder.mutation<
      UpdatePersonalFilterViewResult,
      UpdatePersonalFilterViewParams
    >({
      query: ({
        id,
        isDefault,
        name,
        arrivedFilter,
        readyFilter,
        providerIds,
        gridDensity,
        sortBy = SortingOptions.EarliestToLatest,
        hiddenFields,
        columnsProps,
        columnFiltering,
        columnSorting,
        columnsVisibility,
      }) => {
        const stringifiedColumnsProps = JSON.stringify(columnsProps);
        const stringifiedColumnFilters = JSON.stringify(columnFiltering);
        const stringifiedColumnSorting = JSON.stringify(columnSorting);
        const stringifiedColumnsVisibility = JSON.stringify(columnsVisibility);
        return {
          url: `${Base.V3}/personal_filter_views/${id}`,
          method: 'PUT',
          body: {
            area: 'waiting_room',
            default: isDefault,
            name: _.trim(name),
            data: {
              version: 1,
              provider_ids: providerIds,
              patient_statuses: buildPatientStatusParam({
                arrived: arrivedFilter,
                ready: readyFilter,
              }),
              grid_density: gridDensity,
              sort_by: sortBy,
              hidden_fields: hiddenFields,
              columns_props: stringifiedColumnsProps,
              column_filtering: stringifiedColumnFilters,
              column_sorting: stringifiedColumnSorting,
              columns_visibility: stringifiedColumnsVisibility,
            },
          },
        };
      },
      invalidatesTags: [Tags.PersonalFilterViews],
      transformResponse: (response: UpdatePersonalFilterViewResponse) => {
        if (isPersonalFilterViewResponse(response)) {
          return personalFilterViewTransform(response.data);
        }

        return response;
      },
    }),
    deletePersonalFilterView: builder.mutation<
      void,
      DeletePersonalFilterViewParams
    >({
      query: ({ id }) => ({
        url: `${Base.V3}/personal_filter_views/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.PersonalFilterViews],
    }),
  }),
});

export const {
  useGetPersonalFilterViewsQuery,
  useCreatePersonalFilterViewMutation,
  useUpdatePersonalFilterViewMutation,
  useDeletePersonalFilterViewMutation,
} = personalFilterViewsApi;
