export const Elevations = {
  elevation0: {
    boxShadow: 'none',
  },
  elevation1: {
    boxShadow:
      '0px -15px 1px -15px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)',
  },
  elevation2: {
    boxShadow:
      '0px -15px 10px -15px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
  },
  elevation3: {
    boxShadow:
      '0px -15px 10px -15px rgba(0, 0, 0, 0.05), 0px 2px 4px -2px rgba(0, 0, 0, 0.06), 0px 4px 8px -2px rgba(0, 0, 0, 0.1)',
  },
  elevation4: {
    boxShadow:
      '0px -15px 10px -15px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(0, 0, 0, 0.08)',
  },
  elevation5: {
    boxShadow:
      '0px -15px 10px -15px rgba(0, 0, 0, 0.05), 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08)',
  },
  elevation6: {
    boxShadow:
      '0px -15px 10px -15px rgba(0, 0, 0, 0.05), 0px 24px 48px -12px rgba(0, 0, 0, 0.18)',
  },
  elevation7: {
    boxShadow:
      '0px -15px 10px -15px rgba(0, 0, 0, 0.05), 0px 32px 64px -12px rgba(0, 0, 0, 0.14)',
  },
  elevation8: {
    boxShadow:
      '0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset, 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
};
