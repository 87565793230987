import { Address } from './address';
import { Id, Timestamp } from './common';
import { CreditCards } from './credit-card';
import { CustomColumns } from './custom-columns';
import { CustomerRole } from './customer';
import { IntegrationAppointments, PendingIntegration } from './integrations';
import { LiveStatus } from './live-status';
import { NotificationChannel } from './notifications';
import { PharmacyAttributes } from './pharmacy';
import { ScheduledVisitPatientPractice } from './practice';
import { Preference } from './preference';
import { Role } from './role';
import { UserCreator } from './user-creator';

export type SignInUser = {
  attributes: {
    id: Id;
    // is_location_blocked: boolean;
    role: string;
    secure_authentication_token: string;
  };
  id: Id;
  type: string;
};

export const EnrollmentUserType = 'EnrollmentUser';

export type EnrollmentUser = {
  type: typeof EnrollmentUserType;
  id: Id;
  first_name: string;
  last_name: string;
  email: string;
};

export type CreateUserTermsAgreement = { data: Array<{ id: Id }> };

export type UpdatePaymentMethod = {
  success?: boolean;
  error?: { message: string };
  card?: {
    kind: string;
    last4: string;
  };
};

export type User = {
  id: Id;
  type: string;
  attributes: UserAttributes;
  address?: Address;
  dependents: User[];
  pending_integration?: PendingIntegration;
  customer_role?: CustomerRole;
};

export type UserProfile = {
  body: string | null;
  published: boolean | null;
};

export type UserCreditCardData = {
  data: {
    attributes: UserCreditCard;
  };
};

export type UserCreditCard = {
  id: Id;
  cardable_id: Id;
  customer_id: Id;
  kind: CreditCards;
  last4: string;
  name: string;
  processor: string;
  processor_id: Id;
  created_at: string;
  updated_at: string;
  exp_month: number;
  exp_year: number;
  data: unknown;
};

export type UserAttributes = {
  age: number;
  auxiliary_datapoints: Record<string, string>;
  available_last_confirmed_at: Timestamp;
  card?: UserCreditCard; // TODO: figure out why /api/v3/current_user returns UserCreditCard and /api/v3/visits/{visit_id} returns UserCreditCardData although they use the same user serializer
  can_analyze?: boolean;
  current_visit_id: Id | null;
  created_at: string;
  creator?: UserCreator;
  creator_user_id: Id | null;
  dob: string | null;
  email: string;
  eprescribe_enabled?: boolean;
  first_name: string;
  full_name: string;
  full_name_with_prefix: string;
  gender: 'male' | 'female' | null;
  has_avatar: boolean;
  id: Id;
  is_available: boolean;
  is_schedulable: boolean;
  is_dependent: boolean;
  // is_location_blocked: boolean | null;
  insurance_enabled?: boolean;
  insurance_last_validated_at: string | null;
  insurance_validation_result: {
    validated: boolean;
    validationSkipped: boolean;
  } | null;
  has_overridden_timezone: boolean | null;
  has_reports_role?: boolean;
  health_record_enabled_items?: string[];
  health_record_id: string | null;
  large_url: string;
  last_name: string;
  live_status?: LiveStatus;
  locale: SupportedUserLanguages;
  // location_state_code: string;
  medium_url: string;
  middle_name: string;
  notification_channel: string;
  notification_channels: NotificationChannel[];
  npi?: string | null;
  obfuscated_id: Id;
  occupied_time?: number;
  payment_method_enabled?: boolean;
  pharmacy?: PharmacyAttributes;
  phone_cell: string;
  phone_home: string;
  phone_work: string;
  post_sign_out_target_url: string | null;
  practice_ids: Id[];
  preferences: Preference[];
  preferred_card?: UserCreditCard; // TODO: figure out why /api/v3/current_user returns UserCreditCard and /api/v3/visits/{visit_id} returns UserCreditCardData although they use the same user serializer
  prefix: string | null;
  primary_practice_id: Id;
  processor_mode: 'test' | 'live'; // 'alt' might be an option as well (?)
  profile_valid_at: string | null;
  profile_valid: boolean;
  profile: UserProfile;
  remaining_eligibility_third_party_requests: number;
  role: string;
  roles?: Role[];
  schedulability: boolean;
  secure_authentication_token: string;
  specialty?: string;
  discipline?: string;
  suffix: string | null;
  target_practice_id: Id;
  test_mode: boolean;
  thumb_url: string;
  timeout: number; // In seconds
  timezone: string;
  tiny_url: string;
  vault_access_key: string;
  vault_access_token: string;
  vault_health_doc_id: Id;
  vault_secret_key: string;
  vault_vault_id: Id;
  waiting_visit_count?: number;
  integration_schedulability?: IntegrationAppointments | null;
  integration_type?: string;
  practice_handles?: string[];
  custom_columns?: CustomColumns;
};

export type SupportedUserLanguages = UserLanguages | null;

export enum UserLanguages {
  Es = 'es',
  En = 'en', // We save only the 2 characters language info in our DB
  En_US = 'en_US',
}
export type ScheduledVisitPatientData = {
  address: Address | null;
  address_id: Id;
  card: UserCreditCard | null;
  card_used_for_payment: UserCreditCard | null;
  creator_user_id: Id | null;
  dob: string;
  email: string;
  first_name: string;
  full_name: string;
  gender: 'male' | 'female';
  geolocation: PatientGeolocation;
  id: Id;
  is_dependent: boolean;
  large_url: string;
  last_name: string;
  medium_url: string;
  middle_name: string | null;
  // pharmacy: PharmacyAttributes;
  pharmacy_id: Id | null;
  phone_cell: string | null;
  phone_home: string | null;
  practice_id: Id;
  practices: ScheduledVisitPatientPractice[];
  primary_practice_id: Id;
  role: string;
  thumb_url: string;
  tiny_url: string;
  user_type: string;
  vault_access_key: string;
  vault_health_doc_id: Id;
  vault_provider: string;
  vault_secret_key: string;
  vault_user_id: string;
  vault_vault_id: Id;
};

type PatientGeolocation = {
  country: string;
  state?: string;
  ip: string;
};

export type UserLite = {
  id: Id; //890379,
  full_name: string; //"Daniela Patient4",
  first_name: string; //"Daniela",
  last_name: string; //"Patient4",
  gender: string | null; //null,
  dob: string; //"1989-06-08",
  email: string; //"dvidal+patient4@evisit.com",
  medium_url: string; //"https://evisit.s3.amazonaws.com/static/apps/img/empty-portrait.png",
  thumb_url: string; //"https://evisit.s3.amazonaws.com/static/apps/img/empty-portrait.png",
  vault_vault_id: string; //"D-cegudcbdol1q5cef8h0g",
  vault_health_doc_id: string; //"cegudcbdol1q5cef8h1g",
  health_record_id: string;
  is_dependent: boolean; //false,
  guarantor_email: string | null; //null,
  role: string; //"patient"
};

export type CondensedUserAttributes = {
  age: number;
  email: string;
  first_name: string;
  gender: 'male' | 'female' | null;
  has_avatar?: boolean;
  id: Id;
  is_dependent: boolean;
  last_name: string;
  tiny_url: string;
  thumb_url: string;
  medium_url: string;
  creator?: UserCreator;
};

export type CondensedUser = {
  id: Id;
  type: string;
  attributes: CondensedUserAttributes;
};
