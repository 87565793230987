import { VisitTypeMembership } from 'ev-types';

import api, { Base } from 'ev-api/api';
import { sanitizeAndTransformResponse } from 'ev-api/common/transformers';

import { GetVisitTypeMembershipsByComboIDsParams } from './params';

const visitTypeMembershipsApi = api.injectEndpoints({
  endpoints: builder => ({
    getVisitTypeMembershipsByComboIDs: builder.query<
      VisitTypeMembership[],
      GetVisitTypeMembershipsByComboIDsParams
    >({
      query: ({
        visitTypeAndProviderComboIds,
        state = null,
        country = null,
      }) => ({
        url: `${Base.V3}/visit_type_memberships/bulk_show`,
        method: 'PUT',
        body: {
          limit: 20,
          offset: 0,
          visit_type_and_provider_pairs: visitTypeAndProviderComboIds,
          state: state,
          country: country,
        },
      }),
      transformResponse: sanitizeAndTransformResponse,
    }),
  }),
});

export const { useGetVisitTypeMembershipsByComboIDsQuery } =
  visitTypeMembershipsApi;
