import {
  AttendeeAttemptingJoinResponse,
  AudioToVideoConnectedResponse,
  AudioToVideoDisconnectedResponse,
  AudioToVideoEndedResponse,
  AudioToVideoPendingResponse,
  VisitAttendeeInvitedResponse,
  VisitAttendeeRemovedResponse,
  VisitMediaPermissionDeniedResponse,
} from './event-types';
import { usePusherChannelSubscription } from './pusher';

export enum UserEvents {
  AppointmentCreated = 'appointmentCreated',
  AvatarUploaded = 'avatarUploaded',
  VisitAttendeeAttemptingJoin = 'visitAttendeeAttemptingJoin',
  AudioToVideoPending = 'audioToVideoPending',
  VisitAttendeeInvited = 'visitAttendeeInvited',
  VisitAttendeeAllowed = 'visitAttendeeAllowed',
  VisitAttendeeRemoved = 'visitAttendeeRemoved',
  VisitMediaPermissionDenied = 'visitMediaPermissionDenied',
  AudioToVideoConnected = 'audioToVideoConnected',
  AudioToVideoDisconnected = 'audioToVideoDisconnected',
  AudioToVideoEnded = 'audioToVideoEnded',
}

export const useUserNotificationSubscription = <
  Event extends
    | AttendeeAttemptingJoinResponse
    | VisitAttendeeRemovedResponse
    | AudioToVideoPendingResponse
    | VisitAttendeeInvitedResponse
    | VisitMediaPermissionDeniedResponse
    | AudioToVideoConnectedResponse
    | AudioToVideoDisconnectedResponse
    | AudioToVideoEndedResponse,
>(
  notificationChannel: string,
  event: string,
  handler: (event?: Event) => void,
) => {
  const handleEvent = (event: unknown) => {
    handler(event as Event);
  };

  return usePusherChannelSubscription(notificationChannel, event, handleEvent);
};
