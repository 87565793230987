import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormState {
  hasUnsavedChanges: boolean;
  showUnsavedChangesDialog: boolean;
}

const initialState: FormState = {
  hasUnsavedChanges: false,
  showUnsavedChangesDialog: false,
};

export const formSlice = createSlice({
  name: 'unsavedChanges',
  initialState,
  reducers: {
    setHasUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.hasUnsavedChanges = action.payload;
    },
    setShowUnsavedChangesDialog(state, action: PayloadAction<boolean>) {
      state.showUnsavedChangesDialog = action.payload;
    },
    resetHasUnsavedChanges(state) {
      state.hasUnsavedChanges = false;
      state.showUnsavedChangesDialog = false;
    },
  },
});

export const {
  setHasUnsavedChanges,
  setShowUnsavedChangesDialog,
  resetHasUnsavedChanges,
} = formSlice.actions;

export default formSlice.reducer;
