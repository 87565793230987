import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import { Id, PersonalFilterView } from 'ev-types';

import { GridDensities, SortingOptions } from 'ev-api/types';
import { GridColumnProps } from 'ev-types/custom-columns';

import { WRColumns } from 'app-provider/WaitingRoom/constants';

import { transformPfv } from './utils';

export const PFVDefaultValues = {
  selectedPfv: null as PersonalFilterView | null,
  statusFilters: {
    arrived: false,
    ready: false,
  },
  providerFilters: {} as Record<Id, true>,
  columnsVisibility: {} as GridColumnVisibilityModel,
  initialColumnsVisibility: {} as GridColumnVisibilityModel,
  gridDensity: GridDensities.Standard,
  sortBy: SortingOptions.EarliestToLatest,
  columnsProps: {
    [WRColumns.PatientDetails]: {
      position: 0,
      width: 192,
    },
    [WRColumns.PatientStatus]: {
      position: 1,
      width: 100,
    },
    [WRColumns.ProviderDetails]: {
      position: 2,
      width: 200,
    },
    [WRColumns.WaitTime]: {
      position: 3,
      width: 100,
    },
    [WRColumns.JoinTime]: {
      position: 4,
      width: 100,
    },
    [WRColumns.VisitType]: {
      position: 5,
      width: 150,
    },
    [WRColumns.ContactInfo]: {
      position: 6,
      width: 250,
    },
    [WRColumns.VisitTime]: {
      position: 7,
      width: 150,
    },
    [WRColumns.Language]: {
      position: 8,
      width: 100,
    },
    [WRColumns.PatientLocation]: {
      position: 9,
      width: 150,
    },
    [WRColumns.CompletedTime]: {
      position: 10,
      width: 100,
    },
    [WRColumns.CreatedTime]: {
      position: 11,
      width: 100,
    },
    [WRColumns.SubmittedTime]: {
      position: 12,
      width: 100,
    },
    [WRColumns.SignedTime]: {
      position: 13,
      width: 100,
    },
    [WRColumns.AddendumSignedAt]: {
      position: 14,
      width: 100,
    },
    [WRColumns.OpenText]: {
      position: 15,
      width: 200,
    },
    [WRColumns.TimeInStatus]: {
      position: 16,
      width: 100,
    },
    [WRColumns.Actions]: {
      position: 17,
      width: 230,
    },
  } as GridColumnProps,
  columnSorting: {} as GridSortModel,
  columnFiltering: {
    items: [] as GridFilterItem[],
    logicOperator: 'and' as GridLogicOperator,
    quickFilterLogicOperator: 'and' as GridLogicOperator,
    quickFilterValues: [],
  } as GridFilterModel,
};

export const personalFilterViewSlice = createSlice({
  name: 'personalFilterView',
  initialState: PFVDefaultValues,
  reducers: {
    setPfvReady(state, action: PayloadAction<boolean>) {
      state.statusFilters.ready = action.payload;
    },
    setPfvArrived(state, action: PayloadAction<boolean>) {
      state.statusFilters.arrived = action.payload;
    },
    clearStatusFilters(state) {
      state.statusFilters.ready = false;
      state.statusFilters.arrived = false;
    },
    clearProviderFilters(state) {
      _.each(state.providerFilters, (selected, id) => {
        delete state.providerFilters[id];
      });
    },
    selectPfv(state, action: PayloadAction<PersonalFilterView>) {
      Object.assign(
        state,
        transformPfv(action.payload, state.initialColumnsVisibility),
      );
    },
    clearPfv() {
      return PFVDefaultValues;
    },
    selectProvider(
      state,
      action: PayloadAction<{ id: Id; selected: boolean }>,
    ) {
      const { id, selected } = action.payload;
      if (selected) {
        state.providerFilters[id] = true;
      } else {
        delete state.providerFilters[id];
      }
    },
    setInitialColumnsVisibility(
      state,
      action: PayloadAction<{ initialModel: GridColumnVisibilityModel }>,
    ) {
      state.initialColumnsVisibility = action.payload.initialModel;
    },
    setColumnsVisibility(
      state,
      action: PayloadAction<{
        visibilityModel: GridColumnVisibilityModel;
      }>,
    ) {
      state.columnsVisibility = action.payload.visibilityModel;
    },
    toggleFieldVisibility(
      state,
      action: PayloadAction<{ field: string; hidden: boolean }>,
    ) {
      const { field, hidden } = action.payload;
      state.columnsVisibility[field] = !hidden;
    },
    setGridDensity(state, action: PayloadAction<GridDensities>) {
      state.gridDensity = action.payload;
    },
    setSortBy(state, action: PayloadAction<SortingOptions>) {
      state.sortBy = action.payload;
    },
    setColumnsProps(
      state,
      action: PayloadAction<
        Array<{ columnName: WRColumns; width?: number; position?: number }>
      >,
    ) {
      action.payload.forEach(col => {
        const { columnName, width, position } = col;
        const currentProps = state.columnsProps[columnName];
        state.columnsProps[columnName] = {
          width: width ?? currentProps.width,
          position: position || currentProps.position,
        };
      });
    },
    setWRColumnSorting(state, action: PayloadAction<GridSortModel>) {
      state.columnSorting = action.payload;
    },
    setWRColumnFiltering(state, action: PayloadAction<GridFilterModel>) {
      state.columnFiltering = action.payload;
    },
  },
});
