import { Preference } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';
import { userPreferenceOptimisticUpdate } from 'ev-api/thunks';

import { SetUserPreferencesParams } from './params';

const preferencesApi = api.injectEndpoints({
  endpoints: builder => ({
    setUserPreference: builder.mutation<Preference, SetUserPreferencesParams>({
      query: ({ userId, preference, value }) => ({
        url: `${Base.V2}/preferences/User:${userId}:${preference}`,
        method: 'PUT',
        body: {
          preference: {
            id: `User:${userId}:preference`,
            value,
          },
        },
      }),
      onQueryStarted({ preference, value }, { dispatch, queryFulfilled }) {
        const patchResult = userPreferenceOptimisticUpdate(
          dispatch,
          preference,
          value,
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: [Tags.User],
      transformResponse: sanitizeAndTransformResponseData,
    }),
  }),
});

export const { useSetUserPreferenceMutation } = preferencesApi;
