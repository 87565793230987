import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { CLOUDFRONT_ALERTS_URL } from 'ev-config/config';

import { transformAlerts } from './transformers';
import { CloudfrontAlertWithFilters } from './types';

const cloudfrontQuery = (baseUrl = CLOUDFRONT_ALERTS_URL) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      headers.set('pragma', 'no-cache');
      headers.set('Cache-Control', 'no-cache');
      headers.set('Accept', 'application/json, text/plain, */*');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  });
};

export const cloudfrontApi = createApi({
  reducerPath: 'cloudfrontApi',
  baseQuery: cloudfrontQuery(),
  endpoints: builder => ({
    getCloudfrontAlerts: builder.query<CloudfrontAlertWithFilters[], null>({
      query: () => ({
        url: 'evisit-alerts.json',
        method: 'GET',
      }),
      transformResponse: transformAlerts,
    }),
  }),
});

export const { useGetCloudfrontAlertsQuery } = cloudfrontApi;
