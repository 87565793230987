export enum ParticipantInviteMethod {
  EMAIL = 'email',
  SMS = 'sms',
  USERID = 'user_id',
}

export type ParticipantInvite = {
  kind: ParticipantInviteMethod;
  target: string;
};
