import classNames from 'classnames';
import styled from 'styled-components/macro';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import Icon from 'ev-components/Icon';
import { TextStyle } from 'ev-components/Text';
import Tooltip, { TooltipProps, TooltipVariant } from 'ev-components/Tooltip';
import { EVColors } from 'ev-theme/styles';

export enum BadgeSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum BadgeColor {
  Brand = 'brand',
  BrandContrast = 'brandContrast',
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  DecorativePink = 'decorativePink',
  Neutral = 'neutral',
}

export type BadgeProps = {
  className?: string;
  content?: string | number;
  size?: BadgeSize;
  color?: BadgeColor;
  disabled?: boolean;
  solid?: boolean;
  icon?: IconDefinition;
  id: string;
  invisible?: boolean;
  animated?: boolean;
  role?: string;
  tooltip?: { text: TooltipProps['text'] } & Partial<TooltipProps>;
};

const TextSize = {
  [BadgeSize.Small]: TextStyle.SemiBold.Caption,
  [BadgeSize.Medium]: TextStyle.SemiBold.Footnote,
  [BadgeSize.Large]: TextStyle.SemiBold.Description,
} as const;

const IconSize = {
  [BadgeSize.Small]: '10px',
  [BadgeSize.Medium]: '12px',
  [BadgeSize.Large]: '16px',
} as const;

const Badge = (props: BadgeProps) => {
  const {
    content = '',
    size = BadgeSize.Medium,
    color = BadgeColor.Brand,
    solid = false,
    disabled = false,
    icon,
    className = 'badge',
    id,
    invisible,
    animated = true,
    role,
    tooltip,
  } = props;

  const badgeContent = (
    <StyledBadge
      aria-label={undefined}
      className={classNames(className, size, !disabled && color, {
        disabled,
        solid,
        hasContent: !!content,
        hasIcon: !!icon,
        oneChar: ('' + content).length === 1,
        invisible,
        animated,
        empty: !content && !icon,
      })}
      data-testid={id}
      role={role}
      size={size}
    >
      {icon && <Icon icon={icon} id="badge-icon" />}
      {content}
    </StyledBadge>
  );

  return tooltip ? (
    <Tooltip
      id={tooltip.id ?? 'badge-tooltip'}
      maxWidth={tooltip.maxWidth ?? '250px'}
      variant={tooltip.variant ?? TooltipVariant.Dark}
      {...tooltip}
    >
      {badgeContent}
    </Tooltip>
  ) : (
    badgeContent
  );
};

export default Badge;

const StyledBadge = styled.span<{ size: BadgeSize }>`
  ${({ size }) => TextSize[size]};
  svg {
    width: ${({ size }) => IconSize[size]} !important;
  }
  text-align: center;
  border-radius: 500px;
  &.small {
    min-width: 8px;
    min-height: 8px;
    &.hasContent,
    &.hasIcon {
      min-width: 14px;
      line-height: 14px;
    }
  }
  &.medium {
    min-width: 10px;
    min-height: 10px;
    &.hasContent,
    &.hasIcon {
      min-width: 16px;
      line-height: 16px;
    }
  }
  &.large {
    min-width: 12px;
    min-height: 12px;
    &.hasContent,
    &.hasIcon {
      min-width: 20px;
      line-height: 20px;
    }
  }
  &.brand {
    color: ${EVColors.icon.brand};
    background: ${EVColors.surface.brand};
    &.empty {
      background: ${EVColors.icon.brand};
      outline: 2px solid ${EVColors.surface.brand};
    }
  }
  &.brandContrast {
    color: ${EVColors.icon.brandContrast};
    background: ${EVColors.surface.selectedHover};
    &.empty {
      background: ${EVColors.icon.brandContrast};
      outline: 2px solid ${EVColors.surface.selectedHover};
    }
  }
  &.warning {
    color: ${EVColors.icon.warningHover};
    background: ${EVColors.surface.warning};
    &.empty {
      background: ${EVColors.surface.warningInverse};
      outline: 2px solid ${EVColors.surface.warning};
    }
  }
  &.danger {
    color: ${EVColors.text.danger};
    background: ${EVColors.surface.danger};
    &.empty {
      background: ${EVColors.surface.dangerInverse};
      outline: 2px solid ${EVColors.surface.danger};
    }
  }
  &.success {
    color: ${EVColors.surface.successInverse};
    background: ${EVColors.surface.success};
    &.empty {
      background: ${EVColors.surface.successInverse};
      outline: 2px solid ${EVColors.surface.success};
    }
  }
  &.decorativePink {
    color: ${EVColors.avatar.pink};
    background: ${EVColors.pink};
    &.empty {
      background: ${EVColors.decorative.pink};
      outline: 2px solid ${EVColors.pink};
    }
  }
  &.neutral {
    color: ${EVColors.text.subtle};
    background: ${EVColors.neutral};
    &.empty {
      background: ${EVColors.text.subtle};
    }
  }
  &&.solid {
    outline: none;
    color: ${EVColors.white};
    &.brand {
      background: ${EVColors.surface.brandInverse};
    }
    &.brandContrast {
      background: ${EVColors.surface.brandContrast};
    }
    &.warning {
      color: ${EVColors.icon.warningContrast};
      background: ${EVColors.surface.warningInverse};
    }
    &.danger {
      background: ${EVColors.surface.dangerInverse};
    }
    &.success {
      background: ${EVColors.surface.successInverse};
    }
    &.decorativePink {
      background: ${EVColors.decorative.pink};
    }
    &.neutral {
      background: ${EVColors.text.subtle};
    }
  }
  &&.disabled {
    color: ${EVColors.text.disabled};
    background: ${EVColors.surface.disabled};
  }
  &.hasContent {
    padding: 0 8px;
    svg {
      margin-right: 4px;
    }
  }
  &.oneChar {
    padding: 0;
  }
  &.invisible {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  &.animated {
    transition:
      all 0.5s 0.3s,
      color 0s 0s,
      background-color 0s 0s;
  }
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transform-origin: center;
`;
