import _ from 'lodash';
import React, {
  createContext,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import Slide from '@mui/material/Slide';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { useTranslation } from 'ev-i18n';

import ActionButtonDeprecated from 'ev-components/ActionButtonDeprecated';
import SnackbarToast from 'ev-components/SnackbarToast/SnackbarToast';
import { ToastVariants } from 'ev-components/Toast';
import { NewBannersEnabled as NewBannersEnabledConfig } from 'ev-config/config';
import { clearGlobalError } from 'ev-store/actions';
import { useAppDispatch, useAppSelector } from 'ev-store/redux';
import { EVColors } from 'ev-theme/styles/Colors';

export type ToastType =
  | 'success'
  | 'warning'
  | 'neutral'
  | 'informative'
  | 'error';

const INTERVAL_TIMEOUT = 5000;

const ToastVariantMap: Record<ToastType, ToastVariants> = {
  success: ToastVariants.positive,
  warning: ToastVariants.warning,
  neutral: ToastVariants.neutral,
  informative: ToastVariants.informative,
  error: ToastVariants.negative,
};

type ToastOptions = {
  newBannerOverride?: boolean;
  customIcon?: JSX.Element;
  action?: {
    label: string;
    onClick: () => void;
  };
};

const ToastContext = createContext<{
  setToast: (
    message: string | ReactNode,
    type?: ToastType,
    id?: string,
    options?: ToastOptions,
  ) => void;
}>({ setToast: _.noop });

const ToastInner = ({
  children,
  newBanner,
}: {
  children: React.ReactNode;
  newBanner?: boolean;
}) => {
  const { t } = useTranslation();
  const [toast, setToast] = useState<string | ReactNode>('');
  const [toastId, setToastId] = useState('');
  const [toastType, setToastType] = useState<ToastType>('success');
  const [toastOptions, setToastOptions] = useState<ToastOptions | undefined>(
    undefined,
  );
  const dispatch = useAppDispatch();
  const globalError = useAppSelector(state => state.globalError);

  const intervalRef = useRef<number>();

  const NewBannersEnabled =
    newBanner ?? (NewBannersEnabledConfig || toastOptions?.newBannerOverride);

  const clearToast = () => {
    setToast('');
  };

  const setToastMessage = useCallback(
    (
      message: string | ReactNode,
      type: ToastType = 'success',
      id?: string,
      options?: ToastOptions,
    ) => {
      if (globalError) {
        dispatch(clearGlobalError());
      }
      setToast(message);
      if (type !== toastType) {
        setToastType(type);
      }
      setToastId(id ?? 'evisit-toast');
      setToastOptions(options);
    },
    [globalError, dispatch, toastType],
  );

  useEffect(() => {
    if (toast && !NewBannersEnabled) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = window.setTimeout(clearToast, INTERVAL_TIMEOUT);
    }

    return () => {
      if (intervalRef.current && !NewBannersEnabled) {
        clearInterval(intervalRef.current);
      }
    };
  }, [NewBannersEnabled, toast]);

  const toastOpen = !!toast;

  if (NewBannersEnabled && toastOpen && typeof toast === 'string') {
    return (
      <ToastContext.Provider value={{ setToast: setToastMessage }}>
        <SnackbarToast
          action={toastOptions?.action}
          customIcon={toastOptions?.customIcon}
          id={toastId}
          message={toast}
          onDismiss={clearToast}
          open={toastOpen}
          variant={ToastVariantMap[toastType]}
        />
        {children}
      </ToastContext.Provider>
    );
  }

  return (
    <ToastContext.Provider value={{ setToast: setToastMessage }}>
      <Slide direction="down" in={toastOpen} mountOnEnter unmountOnExit>
        <StyledToast
          $toastType={toastType}
          data-testid={toastId}
          id={toastId}
          key={toastOpen ? 'toast-open' : 'toast-closed'}
          role="alert"
        >
          <Message>{toast}</Message>
          <ActionButtonDeprecated
            displayLabel={false}
            icon={faTimes}
            iconColor={EVColors.white}
            iconSize="xs"
            id="clearToastMessage"
            label={t('Close')}
            onClick={clearToast}
            padding="0"
          />
        </StyledToast>
      </Slide>
      {children}
    </ToastContext.Provider>
  );
};

export const ToastProvider = ({
  children,
  newBanner,
}: {
  children: React.ReactNode;
  newBanner?: boolean;
}) => {
  return (
    <Suspense fallback={<div />}>
      <ToastInner newBanner={newBanner}>{children}</ToastInner>
    </Suspense>
  );
};

const StyledToast = styled.div<{ $toastType: ToastType }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  min-height: 64px;
  height: auto;
  background: ${({ $toastType }) =>
    $toastType === 'success'
      ? `${EVColors.mediumGreen}`
      : `${EVColors.sunflower}`};
  color: ${({ $toastType }) =>
    $toastType === 'success' ? `${EVColors.white}` : `${EVColors.black}`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  padding: 10px 32px;
`;

const Message = styled.div`
  flex-grow: 1;
`;

export default function useToast() {
  const { setToast } = useContext(ToastContext);
  return setToast;
}
