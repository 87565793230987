import _ from 'lodash';

import { User } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';
import { mapTransform } from 'ev-api/common/transformers';

import { GetProviderParams, GetProvidersParams } from './params';
import { getProviderTransform } from './transformers';

const providersApi = api.injectEndpoints({
  endpoints: builder => ({
    getProviders: builder.query<Record<string, User>, GetProvidersParams>({
      query: ({ ids, practiceId = false, query }) => ({
        url: `${Base.V3}/providers/bulk_show`,
        method: 'PUT',
        body: {
          ids: _.uniq(ids),
          practice_id: practiceId,
          query,
        },
      }),
      transformResponse: mapTransform,
      providesTags: [Tags.Providers],
    }),
    getProvider: builder.query<User, GetProviderParams>({
      query: ({ id, practiceId }) => ({
        url: `${Base.V3}/providers/bulk_show`,
        method: 'PUT',
        body: { ids: [id], practice_id: practiceId },
      }),
      transformResponse: getProviderTransform,
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useLazyGetProvidersQuery,
  useLazyGetProviderQuery,
  useGetProviderQuery,
} = providersApi;
