import _ from 'lodash';
import styled from 'styled-components/macro';

import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import Toast, { ToastVariants } from 'ev-components/Toast';

const SnackbarToast = ({
  message,
  variant,
  onDismiss,
  id,
  open,
  anchor,
  action,
  customIcon,
}: {
  message: string;
  variant: ToastVariants;
  onDismiss: () => void;
  id: string;
  open: boolean;
  anchor?: SnackbarOrigin;
  customIcon?: JSX.Element;
  action?: {
    label: string;
    onClick: () => void;
  };
}) => {
  const hideAfter = _.min([_.max([message.length * 50, 4000]), 10000]);
  return (
    <StyledSnackbar
      anchorOrigin={anchor || { vertical: 'top', horizontal: 'center' }}
      autoHideDuration={hideAfter}
      onClose={onDismiss}
      open={open}
    >
      <div>
        <Toast
          action={action}
          customIcon={customIcon}
          dismiss={{ onClick: onDismiss }}
          id={id}
          title={message}
          variant={variant}
        />
      </div>
    </StyledSnackbar>
  );
};

export default SnackbarToast;

const StyledSnackbar = styled(Snackbar)`
  top: 8px;
`;
