import styled from 'styled-components/macro';

import Stack from '@mui/material/Stack';

import {
  faCircleCheck,
  faCircleInfo,
  faTimes,
  faTimesCircle,
  faTriangleExclamation,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

import Button, { ButtonTypes } from 'ev-components/Button';
import Icon from 'ev-components/Icon';
import Text from 'ev-components/Text';
import { EVColors } from 'ev-theme/styles';

export enum ToastVariants {
  informative = 'informative',
  positive = 'positive',
  negative = 'negative',
  neutral = 'neutral',
  warning = 'warning',
}

const iconsMap: Record<
  ToastVariants,
  { icon: IconDefinition | null; color: string }
> = {
  informative: { icon: faCircleInfo, color: EVColors.icon.brand },
  positive: { icon: faCircleCheck, color: EVColors.icon.success },
  negative: { icon: faTimesCircle, color: EVColors.icon.danger },
  warning: { icon: faTriangleExclamation, color: EVColors.icon.warning },
  neutral: { icon: null, color: EVColors.icon.default },
};

const gradientsMap: Record<
  ToastVariants,
  {
    r1: number;
    g1: number;
    b1: number;
    r2?: number;
    g2?: number;
    b2?: number;
  }
> = {
  informative: { r1: 40, g1: 95, b1: 245 },
  positive: { r1: 6, g1: 167, b1: 100 },
  negative: { r1: 243, g1: 40, b1: 55 },
  neutral: { r1: 255, g1: 255, b1: 255, r2: 249, g2: 250, b2: 251 },
  warning: { r1: 255, g1: 255, b1: 255, r2: 245, g2: 188, b2: 40 },
};

const Toast = ({
  id,
  title,
  variant,
  action,
  dismiss,
  customIcon,
}: {
  id: string;
  title: string;
  variant: ToastVariants;
  customIcon?: JSX.Element;
  action?: {
    label: string;
    onClick: () => void;
  };
  dismiss?: {
    onClick: () => void;
  };
}) => {
  const { r1, g1, b1, r2, g2, b2 } = gradientsMap[variant];
  const { color: iconColor, icon } = iconsMap[variant];

  return (
    <Stack
      sx={{
        backgroundColor: EVColors.white,
        borderTop: `0.5px solid ${EVColors.aluminum}`,
        borderRight: `0.5px solid ${EVColors.aluminum}`,
        borderBottom: `1px solid ${EVColors.aluminum}`,
        borderLeft: `0.5px solid ${EVColors.aluminum}`,
        boxShadow: `0px 1px 0px 0px ${EVColors.white} inset, 0px 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 4px 4px 0px rgba(0, 0, 0, 0.04)`,
        borderRadius: '8px',
        minHeight: '48px',
      }}
    >
      <Stack alignItems="center" data-testid={id} direction="row">
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          sx={{
            borderRadius: '8px',
            p: '16px',
            background: `linear-gradient(
    270deg,
    rgba(${r1},${g1},${b1},0) 0%,
    rgba(${r2 || r1},${g2 || g1},${b2 || b1},0.1) 100%
  )`,
          }}
        >
          {customIcon
            ? customIcon
            : variant !== ToastVariants.neutral && (
                <Icon color={iconColor} icon={icon} />
              )}
          <Text.Description>{title}</Text.Description>
        </Stack>
        {action && (
          <StyledActionButton
            id={`${id}-action`}
            onClick={action.onClick}
            variant={ButtonTypes.Tertiary}
          >
            {action.label}
          </StyledActionButton>
        )}
        {dismiss && (
          <Stack alignItems="center" direction="row">
            <VerticalBar />
            <DismissButton
              icon={faTimes}
              iconColor={EVColors.gunpowder}
              id={`${id}-dismiss`}
              onClick={dismiss.onClick}
              variant={ButtonTypes.Tertiary}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Toast;

const StyledActionButton = styled(Button)`
  max-height: 18px;
`;

const VerticalBar = styled.div`
  width: 1px;
  height: 24px;
  background: ${EVColors.silverBullet};
`;

const DismissButton = styled(Button)`
  &.ev-fixed-icon {
    min-width: 0px;
    max-width: 20px;
    height: 20px;
    margin-left: 9.4px;
    margin-right: 10.4px;
  }
`;
