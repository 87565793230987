import GlobalStyles from 'global-styles';
import { withCoreProviders } from 'Providers';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import { useGetCurrentUserQuery } from 'ev-api/core';
import { UserEvents, useUserNotificationSubscription } from 'ev-api/pusher';
import SkipToMain from 'ev-common/SkipToMain';
import { URL_PREFIX } from 'ev-config/config';
import { useActiveSessionRedirect } from 'ev-hooks/activeSessionRedirect';
import { useAppNavigate } from 'ev-hooks/navigate';
import { useTokenAuth } from 'ev-hooks/tokenAuth';
import { clearAvatar } from 'ev-store/actions';
import { useAppDispatch } from 'ev-store/redux';
import { buildParamsMap } from 'ev-utils/browser';
import { isPath } from 'ev-utils/paths';
import { isPatient } from 'ev-utils/user';

import { ADMIN_ROOT } from 'app-admin/paths';
import LoginPaths from 'app-login/paths';
import PatientPaths from 'app-patient/paths';
import { QTC_ROOT } from 'app-qtc/constants';
import VideoAppSwitch from 'app-video-shared/components/VideoAppSwitch';

const LoginApp = lazy(() => import('./app-login'));
const AdminApp = lazy(() => import('./app-admin'));
const QtcApp = lazy(() => import('./app-qtc'));
const OnboardingApp = lazy(() => import('./app-onboarding'));
const PatientApp = lazy(() => import('./app-patient'));
const ProviderApp = lazy(() => import('./app-provider'));
const RegistrationApp = lazy(() => import('./app-registration'));

const Main = () => {
  const { isSigningIn } = useTokenAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    // Drop params that are not used by the app
    const shouldBeClearOfExtraParams =
      location.pathname.includes(`/my_account`);
    const paramsKeys = Array.from(searchParams.keys());
    if (!isSigningIn && shouldBeClearOfExtraParams && paramsKeys.length > 1) {
      setSearchParams(buildParamsMap(searchParams, { include: 'practice' }), {
        replace: true,
      });
    }
  }, [isSigningIn, location, searchParams, setSearchParams]);

  if (isSigningIn) {
    return null;
  }

  return (
    <>
      <GlobalStyles />
      <Suspense fallback={<div />}>
        <SkipToMain />
        <Routes>
          <Route element={<LoginApp />} path={`${URL_PREFIX}/login/*`} />
          <Route element={<AdminApp />} path={`${ADMIN_ROOT}/*`} />
          <Route element={<QtcApp />} path={`${QTC_ROOT}/*`} />
          <Route
            element={<OnboardingApp />}
            path={`${URL_PREFIX}/onboarding/*`}
          />
          <Route element={<UserApp />} path={`${URL_PREFIX}/provider/*`} />
          <Route
            element={<RegistrationApp />}
            path={`${URL_PREFIX}/patient/${PatientPaths.Register}`}
          />
          <Route element={<UserApp />} path={`${URL_PREFIX}/patient/*`} />
          <Route element={<VideoAppSwitch />} path={`${URL_PREFIX}/video/*`} />
          <Route element={<Root />} path={`${URL_PREFIX}/`} />
        </Routes>
      </Suspense>
    </>
  );
};

export default withCoreProviders(Main);

const Root = () => {
  useActiveSessionRedirect();

  return null;
};

const UserApp = () => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isRegistrationFlow = isPath(location.pathname, PatientPaths.Register);

  const {
    data: currentUser,
    isFetching,
    isError,
    refetch: refetchCurrentUser,
  } = useGetCurrentUserQuery(undefined, { skip: isRegistrationFlow });

  const handleAvatarUploaded = async () => {
    await refetchCurrentUser();
    dispatch(clearAvatar());
  };

  useUserNotificationSubscription(
    currentUser?.attributes.notification_channel || '',
    UserEvents.AvatarUploaded,
    handleAvatarUploaded,
  );

  const isEnteringProviderPath = location.pathname.startsWith(
    `${URL_PREFIX}/provider`,
  );
  const isEnteringPatientPath = location.pathname.startsWith(
    `${URL_PREFIX}/patient`,
  );

  useEffect(() => {
    if (isError) {
      navigate(`${URL_PREFIX}/login/${LoginPaths.Login}`, null, true);
    } else if (!isFetching && currentUser) {
      if (isPatient(currentUser) && isEnteringProviderPath) {
        navigate(`${URL_PREFIX}/patient/${PatientPaths.Home}`, null, true);
      } else if (!isPatient(currentUser) && isEnteringPatientPath) {
        navigate(`${URL_PREFIX}/provider/patients`, null, true);
      }
    }
  }, [
    currentUser,
    isEnteringPatientPath,
    isEnteringProviderPath,
    isError,
    isFetching,
    navigate,
  ]);

  if (isRegistrationFlow) {
    return <RegistrationApp />;
  }

  if (!currentUser) {
    return null;
  }

  if (!isPatient(currentUser)) {
    return <ProviderApp />;
  } else {
    return <PatientApp />;
  }
};
