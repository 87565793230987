import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Id } from 'ev-types';

import { CustomFormNotificationEvent } from 'ev-api/pusher/event-types';

interface Notifications {
  customForms: {
    events: CustomFormNotificationEvent[];
    toastActionEvent: CustomFormNotificationEvent | null;
  };
}

const initialState: Notifications = {
  customForms: {
    events: [],
    toastActionEvent: null,
  },
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addCustomFormNotification: (
      state,
      action: PayloadAction<CustomFormNotificationEvent>,
    ) => {
      state.customForms.events.push(action.payload);
    },
    clearCustomFormNotification: (state, action: PayloadAction<Id>) => {
      state.customForms.events = state.customForms.events.filter(
        ({ visit_id }) => visit_id !== action.payload,
      );
    },
    clearAllCustomFormNotifications: state => {
      state.customForms.events = [];
    },
    setCustomFormToastActionEvent: (
      state,
      action: PayloadAction<CustomFormNotificationEvent | null>,
    ) => {
      state.customForms.toastActionEvent = action.payload;
    },
  },
});
