import api, { Base } from 'ev-api/api';
import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';
import {
  AvailableReports,
  RetrievedReport,
  RunReportActionInformation,
} from 'ev-types/reports';

import {
  ReportsParams as AvailableReportsParams,
  GetReportRunParams,
  RunReportsParams,
} from './params';

const ReportsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAvailableReports: builder.query<
      AvailableReports,
      AvailableReportsParams
    >({
      query: ({ practiceId }) => {
        return {
          url: `${Base.V2}/reports`,
          method: 'GET',
          params: {
            practice_id: practiceId,
          },
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
    }),
    runReport: builder.mutation<RunReportActionInformation, RunReportsParams>({
      query: ({ reportId, defaultTargetPracticeId, finish, start }) => {
        return {
          url: `${Base.V2}/reports/${reportId}/run`,
          method: 'POST',
          body: {
            defaultTargetPracticeId: Number(defaultTargetPracticeId),
            finish,
            start,
          },
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
    }),
    getReportRun: builder.query<RetrievedReport, GetReportRunParams>({
      query: ({ runId }) => {
        return {
          url: `${Base.V2}/reports/get_report_run/${runId}`,
          method: 'GET',
        };
      },
      transformResponse: sanitizeAndTransformResponseData,
    }),
  }),
});

export const {
  useGetAvailableReportsQuery,
  useRunReportMutation,
  useGetReportRunQuery,
} = ReportsApi;
