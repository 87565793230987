import _ from 'lodash';

import api, { Base, Tags } from 'ev-api/api';
import { userTransform } from 'ev-api/core/users/transformers';
import { GetPatients, SearchPatients } from 'ev-types/patients';

import { GetPatientsParams, SearchPatientsParams } from './params';
import { GetPatientsResponse } from './responses';

const patientsApi = api.injectEndpoints({
  endpoints: builder => ({
    getPatients: builder.query<GetPatients, GetPatientsParams>({
      query: ({
        status,
        activity,
        search,
        pageSize,
        page,
        orderBy,
        sortBy,
        filters,
      }) => ({
        url: `${Base.V3}/patients/search`,
        method: 'PUT',
        body: {
          status,
          activity,
          search_term: search,
          limit: pageSize,
          offset: page * pageSize,
          order_by: orderBy,
          sort_by: sortBy,
          filters,
        },
      }),
      transformResponse: ({ data, included, meta }: GetPatientsResponse) => {
        return {
          // The API can return duplicates
          patients: _.uniqBy(data, 'attributes.id').map(item =>
            userTransform({ data: item, included }),
          ),
          total: meta.total,
          total_in_set: meta.total_in_set,
        };
      },
      providesTags: [Tags.SearchPatients],
    }),
    searchPatients: builder.query<SearchPatients, SearchPatientsParams>({
      query: ({ email, firstName, lastName, dob }) => ({
        url: `${Base.V4}/patients/search_patients`,
        params: {
          email,
          first_name: firstName,
          last_name: lastName,
          dob,
        },
      }),
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useLazyGetPatientsQuery,
  useLazySearchPatientsQuery,
} = patientsApi;
