import _ from 'lodash';

import { Sanitized, sanitizeIds } from 'ev-api/utils';

export function sanitizeAndTransformResponseData<T>(data = {}): T {
  return sanitizeIds(data) as T;
}

export function sanitizeAndTransformResponse<T, K>(response: { data: K }): T {
  return sanitizeIds(response).data as T;
}

export function sanitizeAndTransformInsurancePlansResponse<T, K>(response: {
  practice_insurance_plans: K;
}): T {
  return sanitizeIds(response).practice_insurance_plans as T;
}

export function mapTransform<T>(response: {
  data: T[];
}): Sanitized<Record<string, T>> {
  const sanitizedData = sanitizeIds(response).data;
  return _.keyBy(sanitizedData, 'id');
}
