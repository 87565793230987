import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';

import { useTranslation } from 'ev-i18n';

import { ButtonProps, ButtonSize, ButtonTypes } from 'ev-components/Button';
import Popover, { PopoverProps } from 'ev-components/Popover';
import { TooltipVariant } from 'ev-components/Tooltip/Tooltip';

import DateRangePicker, { DateRangePickerProps } from './DateRangePicker';
import {
  findDateRangeShortcutByDateRangeValue,
  getDateRangeLabel,
  useGetDateRangeShortcutLabel,
} from './utils';

interface DateRangeButtonProps extends DateRangePickerProps {
  id: string;
  disabled?: boolean;
  buttonSize?: 'small' | 'medium' | 'large' | 'extraLarge';
  buttonMinWidth?: ButtonProps['minWidth'];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  label?: ButtonProps['label'];
  variant?: ButtonProps['variant'];
  buttonTooltipVariant?: TooltipVariant;
  buttonTooltipText?: string;
  displayRangeAsLabel?: boolean;
}

const DateRangeButton = ({
  id,
  disabled = false,
  buttonSize = 'medium',
  buttonMinWidth = '150px',
  anchorOrigin = { horizontal: 'left', vertical: 'bottom' },
  transformOrigin = { horizontal: 'left', vertical: 'top' },
  variant = ButtonTypes.Special,
  buttonTooltipVariant,
  buttonTooltipText,
  value,
  onChange,
  shortcuts,
  format,
  readOnly,
  disableFuture,
  disablePast,
  calendars,
  displayStaticWrapperAs,
  listItemHeight,
  listItemWidth,
  displayRangeAsLabel,
}: DateRangeButtonProps) => {
  const { t } = useTranslation();
  const getDateRangeShortcutLabel = useGetDateRangeShortcutLabel();

  const shortcut = shortcuts
    ? findDateRangeShortcutByDateRangeValue(value, shortcuts, format)
    : null;

  const label = displayRangeAsLabel
    ? getDateRangeLabel(value)
    : shortcut
      ? getDateRangeShortcutLabel(shortcut)
      : t('Custom range');

  return (
    <Popover
      anchorButton={{
        disabled,
        id,
        label,
        minWidth: buttonMinWidth,
        size: buttonSize as ButtonSize,
        startIcon: faCalendarAlt,
        variant,
        showOpenIndicator: true,
        tooltipVariant: buttonTooltipVariant,
        tooltipText: buttonTooltipText,
        hasToolTip: !!buttonTooltipText,
      }}
      anchorOrigin={anchorOrigin}
      id={`${id}-popover`}
      paperPadding="0px"
      transformOrigin={transformOrigin}
      width={500}
      anchorTextLeftAligned
    >
      <DateRangePicker
        calendars={calendars}
        disableFuture={disableFuture}
        disablePast={disablePast}
        displayStaticWrapperAs={displayStaticWrapperAs}
        format={format}
        listItemHeight={listItemHeight}
        listItemWidth={listItemWidth}
        onChange={onChange}
        readOnly={readOnly}
        shortcuts={shortcuts}
        value={value}
      />
    </Popover>
  );
};

export default DateRangeButton;
