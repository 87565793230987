import { useCallback } from 'react';

import { Id } from 'ev-types';

import api, { Base } from 'ev-api/api';
import { mimicUserInOtherTab } from 'ev-utils/browser';

type TokenParams = {
  practiceId: Id;
  patientId: Id;
};

const mimicApi = api.injectEndpoints({
  endpoints: builder => ({
    getMimicToken: builder.query<string, TokenParams>({
      query: ({ practiceId, patientId }) => ({
        url: `${Base.V2}/practices/${practiceId}/patients/${patientId}/mimic`,
        method: 'POST',
      }),
      transformResponse: (response: {
        secure_authentication_token: string;
      }) => {
        return response.secure_authentication_token;
      },
    }),
  }),
});

const { useLazyGetMimicTokenQuery } = mimicApi;

export const useMimicUser = () => {
  const [getToken] = useLazyGetMimicTokenQuery();

  return useCallback(
    async ({ practiceId, patientId }: TokenParams) => {
      const authToken = await getToken({ practiceId, patientId }).unwrap();
      mimicUserInOtherTab(authToken);
    },
    [getToken],
  );
};
