import _ from 'lodash';

import { ChatSession, ChatSessionEntry } from 'ev-types';

import { ChatRecordResponse, ChatSessionResponse } from 'ev-api/core';
import { sanitizeId } from 'ev-api/utils';

const transformChatEntry = (entry: ChatRecordResponse): ChatSessionEntry => {
  const { attributes } = entry;
  return {
    id: entry.id,
    user_id: sanitizeId(attributes.user_id),
    health_record_id: attributes.health_record_id,
    document_id: attributes.document_id,
    session_id: sanitizeId(attributes.chat_session_id),
  };
};

export const transformChatSession = (
  response: ChatSessionResponse,
): ChatSession => {
  const { data, included } = response;
  return {
    id: data.id,
    vault_id: data.attributes.vault_id,
    entries: _.map(included, transformChatEntry),
  };
};
