import { createContext, useContext } from 'react';

import Environments from 'ev-types/environments';

const EnvironmentContext = createContext<Environments>(
  process.env.NODE_ENV as Environments,
);

export const EnvironmentProvider = ({
  children,
  __environment,
}: {
  children: React.ReactNode;
  __environment?: Environments;
}) => {
  return (
    <EnvironmentContext.Provider
      value={__environment || (process.env.NODE_ENV as Environments)}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = (): Environments => {
  return useContext(EnvironmentContext);
};
