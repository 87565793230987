import { ChartCodeAttributes } from 'ev-types';

import api, { Base } from 'ev-api/api';

import {
  AddChartCodesFavoriteParams,
  GetChartCodesFavoriteParams,
  GetChartCodesParams,
} from './params';
import { chartCodeResponseTransform } from './transformers';

const chartCodeApi = api.injectEndpoints({
  endpoints: builder => ({
    getChartCodes: builder.query<ChartCodeAttributes[], GetChartCodesParams>({
      query: ({ kind, practice_id, query }) => ({
        url: `${Base.V3}/chart_codes/search`,
        params: { kind, practice_id, query },
        method: 'GET',
      }),
      transformResponse: chartCodeResponseTransform,
    }),
    getChartCodeFavorites: builder.query<
      ChartCodeAttributes[],
      GetChartCodesFavoriteParams
    >({
      query: ({ kind, practice_id }) => ({
        url: `${Base.V3}/chart_codes/favorites`,
        params: { kind, practice_id },
        method: 'GET',
      }),
      transformResponse: chartCodeResponseTransform,
    }),
    addChartCodeFavorite: builder.mutation<void, AddChartCodesFavoriteParams>({
      query: ({ kind, practice_id, code }) => ({
        url: `${Base.V3}/chart_codes/favorites`,
        params: { kind, practice_id, code },
        method: 'POST',
      }),
    }),
    removeChartCodeFavorite: builder.mutation<
      void,
      AddChartCodesFavoriteParams
    >({
      query: ({ kind, practice_id, code }) => ({
        url: `${Base.V3}/chart_codes/favorites`,
        params: { kind, practice_id, code },
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetChartCodesQuery,
  useLazyGetChartCodeFavoritesQuery,
  useAddChartCodeFavoriteMutation,
  useRemoveChartCodeFavoriteMutation,
} = chartCodeApi;
