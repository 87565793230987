import { Id } from './common';

export enum PracticePreference {
  AcceptManualInsurance = 'accept_manual_insurance',
  AcceptSupportedInsurance = 'accept_supported_insurance',
  AllowUnsupportedBrowsers = 'feature_flag_allow_unsupported_browsers',
  CreatePatient = 'feature_flag_create_patient',
  CompleteVisit = 'complete_visit_in_waiting_room_and_scheduling',
  DefaultPharmacy = 'default_pharmacy',
  DisallowAvailabilityToggle = 'disallow_availability_toggling',
  DisableMyAccountPatient = 'patient_remove_my_account_options',
  FamilyAccounts = 'family_accounts',
  HealthRecordOptions = 'health_record_options',
  HideUnsupportedBrowserNativeAppAccess = 'hide_unsupported_browser_native_app_access',
  LiveStatus = 'feature_flag_waiting_room_live_status',
  QuickEntryVisits = 'quick_entry_visits',
  SchedulingEnabled = 'scheduling_enabled',
  SetAsReady = 'set_patient_status_ready_enabled',
  SignNotesAnywhere = 'sign_notes_anywhere',
  SupportPhoneNumber = 'support_phone_number',
  ValidateInsuranceVisitConfirmationFlow = 'validate_insurance_visit_confirmation_flow',
  UseSelfPayPriceWhenPlanIsOther = 'use_self_pay_price_when_plan_is_other',
  ChartEnabled = 'charting_enabled',
  ChartingICDCodes = 'charting_icd_codes_enabled',
  ChartingCPTCodes = 'charting_cpt_codes_enabled',
  CustomTerms = 'custom_terms',
  ProviderSpecificVisit = 'feature_flag_provider_specific_visits',
  SupportsPTZ = 'show_controls_for_pan_tilt_zoom_cameras',
  PatientRequestVerbiage = 'patient_request_verbiage',
  HideSexAndAgeFromPatientOnReviewDetailsPage = 'hide_sex_and_age_from_patient_on_review_details_page',
  HideUserNameWhoUploadedAttachment = 'hide_user_name_who_uploaded_attachment_when_role_is_patient',
  CaptureFullStory = 'fullstory_data_capture',
  ChatDifferentiation = 'chat_alerts_for_patient_or_attendees_messages_only',
  RequirePatientName = 'require_patient_name',
  RequirePatientPhone = 'require_patient_phone',
  RequirePatientDOB = 'require_patient_date_of_birth',
  RequirePatientPreferredLanguage = 'require_patient_preferred_language',
  RequirePatientSex = 'require_patient_sex',
  RequirePatientAddress = 'require_patient_address',
  RequirePatientCity = 'require_patient_city',
  RequirePatientState = 'require_patient_state',
  RequirePatientZipCode = 'require_patient_zipcode',
  VisitCallPlatform = 'visit_call_platform',
}

export enum PracticeEnablementConfig {
  CalendarMultiPracticeView = 'feature_flag_calendar_multi_practice_view',
  EnableExpressRoom = 'feature_flag_enable_express_rooms',
  PatientFacingNotes = 'feature_flag_patient_notes_in_visit_history',
  Remove24HourCheckInLimit = 'feature_flag_remove_24_hour_check_in_limit',
  VisitFilterSorting = 'feature_flag_visit_filter_sorting',
  ResetConnection = 'feature_flag_reset_video_connection',
  PictureInPicture = 'feature_flag_picture_in_picture',
  PatientsPageUpdate = 'feature_flag_patient_page_update',
  OrientationUnlock = 'feature_flag_orientation_unlock',
  MFAForRad = 'feature_flag_rad_mfa',
  BlockSafari12 = 'feature_flag_block_safari_12',
  GlobalStylingUpdates = 'feature_flag_global_styling_updates',
  HonorCustomColumns = 'feature_flag_honor_custom_columns',
  UseNewVideoVendor = 'feature_flag_use_new_video_vendor',
  ThirdPartyInterpreters = 'feature_flag_third_party_interpreters',
  ThirdPartyAudioInterpreters = 'feature_flag_third_party_audio_interpreters',
  MultiProviderSupport = 'feature_flag_multi_provider_visit_support',
  CustomVisitStatuses = 'feature_flag_custom_visit_statuses',
  OpenExpressInSameTab = 'feature_flag_open_express_in_same_tab',
  CopyShareableLinkEnabled = 'feature_flag_copy_shareable_link',
  RedirectToNewEvault = 'evault_redirect_to_backend',
  HonorReasonForVisit = 'feature_flag_reason_for_visit_custom_column',
  ClinicalCreateVisit = 'feature_flag_clinical_create_visit',
  OnCallHeaderStylingUpdates = 'feature_flag_on_call_header_styling_updates',
  AdvancedParticipantInvite = 'feature_flag_advanced_participant_invite',
}

export enum UserPreference {
  AudioWaitingRoom = 'audio_waiting_room',
  AudioAutoLogout = 'audio_auto_logout',
  AudioChatMessages = 'audio_chat_messages',
  AudioMultipartyVisit = 'audio_multiparty_visit',
  CardOverride = 'card_override',
  ChartEnabled = 'charting_enabled',
  DisallowVisitSummaryDownload = 'disallow_visit_summary_downloads',
  ExpressEnabled = 'express_enabled',
  HideCompleteVisitAcknowledgment = 'hide_complete_visit_acknowledgement',
  PatientNotificationBlacklist = 'patient_notification_blacklist',
  ProviderNotificationBlacklist = 'physician_notification_blacklist',
  ShowLobby = 'show_lobby',
  ShowLobbyPreferenceNotification = 'show_lobby_preference_notification',
  CustomerRoles = 'feature_flag_customer_roles',
}

export type Preference = {
  allow_empty: boolean;
  default: PreferenceValue;
  description: string;
  form_type: string;
  group: string;
  id: Id;
  name: PracticePreference | UserPreference;
  number: boolean;
  owner_id: Id;
  owner_type: string;
  // schema: null
  trait_type: string;
  type: string;
  value: PreferenceValue;
};

export type PreferenceValue =
  | string
  | boolean
  | Record<string, unknown>
  | string[]
  | Record<string, string[] | Record<string, unknown>>
  | null
  | undefined;

export type ScheduledVisitPreference = {
  default: PreferenceValue;
  description: string;
  group: string;
  id: Id;
  name: PracticePreference | UserPreference;
  owner_id: Id;
  owner_type: string;
  value: PreferenceValue;
};
