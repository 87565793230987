import api, { Base } from 'ev-api/api';
import { SuccessResponse } from 'ev-api/common/SuccessResponse';

import { SendMFACodeParams, VerifyMFACodeParams } from './params';
import { VerifyCodeResponseData } from './responses';

const integrationApi = api.injectEndpoints({
  endpoints: builder => ({
    sendCode: builder.mutation<SuccessResponse, SendMFACodeParams>({
      query: ({ dependent_id, patientid, type }) => ({
        url: `${Base.V2}/integrations/authenticate`,
        method: 'POST',
        body: {
          patientid,
          dependent_id,
          type,
        },
      }),
    }),
    verifyCode: builder.mutation<VerifyCodeResponseData, VerifyMFACodeParams>({
      query: ({ dependent_id, token }) => ({
        url: `${Base.V2}/integrations/token/authenticate`,
        method: 'POST',
        body: {
          dependent_id,
          token,
        },
      }),
    }),
  }),
});

export const { useSendCodeMutation, useVerifyCodeMutation } = integrationApi;
