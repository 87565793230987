import _ from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import CustomDialog from 'ev-components/CustomDialog';
import Dialog, { DialogProps as BaseDialogProps } from 'ev-components/Dialog';

const DialogContext = createContext({
  openDialog: _.noop,
  openCustomDialog: _.noop,
  closeDialog: _.noop,
  setDialogBusy: _.noop,
});

type CustomDialogOptions = {
  overrideMaxWidth?: boolean;
  required?: boolean;
  onDismiss?: () => void;
};

type DialogProps = Omit<BaseDialogProps, 'open'>;

export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [busy, setDialogBusy] = useState(false);

  const [dialogProps, setDialogProps] = useState<DialogProps | null>(null);
  const [customDialogProps, setCustomDialog] = useState<{
    content: React.ReactNode;
    options: CustomDialogOptions;
  } | null>(null);

  const openDialog = useCallback((props: DialogProps) => {
    setDialogProps(props);
    setCustomDialog(null);
    setOpen(true);
  }, []);

  const openCustomDialog = useCallback(
    (
      content: React.ReactNode,
      options: CustomDialogOptions = {
        overrideMaxWidth: false,
        required: false,
      },
    ) => {
      setDialogProps(null);
      setCustomDialog({ content, options });
      setOpen(true);
    },
    [],
  );

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const renderDialog = () => {
    if (!dialogProps) {
      return null;
    }
    return (
      <Dialog
        busy={busy}
        onAccept={closeDialog}
        onClose={closeDialog}
        onReject={closeDialog}
        open={open}
        {...dialogProps}
      />
    );
  };

  const renderCustomDialog = () => {
    if (!customDialogProps) {
      return null;
    }

    return (
      <CustomDialog
        onDismiss={() => {
          closeDialog();
          if (customDialogProps.options.onDismiss) {
            customDialogProps.options.onDismiss();
          }
        }}
        open={open}
        {...customDialogProps.options}
      >
        {customDialogProps.content}
      </CustomDialog>
    );
  };

  const value = useMemo(() => {
    return { openDialog, openCustomDialog, closeDialog, setDialogBusy };
  }, [openDialog, openCustomDialog, closeDialog, setDialogBusy]);

  return (
    <DialogContext.Provider value={value}>
      {renderDialog()}
      {renderCustomDialog()}
      {children}
    </DialogContext.Provider>
  );
};

const useDialog = (): {
  openDialog: (props: DialogProps) => void;
  openCustomDialog: (
    content: React.ReactNode,
    options?: CustomDialogOptions,
  ) => void;
  closeDialog: () => void;
  setDialogBusy: (busy: boolean) => void;
} => {
  const { openDialog, openCustomDialog, closeDialog, setDialogBusy } =
    useContext(DialogContext);

  return {
    openDialog,
    openCustomDialog,
    closeDialog,
    setDialogBusy,
  };
};

export default useDialog;
